type Breakpoint = 'mobile' | 'tablet' | 'desktop' | 'large-desktop';

function initBreakpointContainer(el: HTMLElement) {
  const container = el;
  const breakpoint = container.dataset.breakpoint as Breakpoint;

  const isOutsideBreakpoint = (breakpoint: Breakpoint) => {
    switch (breakpoint) {
      case 'mobile':
        return window.innerWidth >= 768;
      case 'tablet':
        return window.innerWidth < 768 || window.innerWidth >= 992;
      case 'desktop':
        return window.innerWidth < 992 || window.innerWidth >= 1200;
      case 'large-desktop':
        return window.innerWidth < 1200;
    }
  };

  if (isOutsideBreakpoint(breakpoint)) {
    console.log('outside breakpoint', breakpoint);
    container.setAttribute('aria-hidden', 'true');
    container.setAttribute('tabindex', '-1');
    container.classList.add('hidden');
  } else {
    console.log('inside breakpoint', breakpoint);
    container.removeAttribute('aria-hidden');
    container.removeAttribute('tabindex');
    container.classList.remove('hidden');
  }

  const handleResize = () => {
    if (isOutsideBreakpoint(breakpoint)) {
      console.log('outside breakpoint', breakpoint);
      container.setAttribute('aria-hidden', 'true');
      container.setAttribute('tabindex', '-1');
      container.classList.add('hidden');
    } else {
      console.log('inside breakpoint', breakpoint);
      container.removeAttribute('aria-hidden');
      container.removeAttribute('tabindex');
      container.classList.remove('hidden');
    }
  };

  window.addEventListener('resize', handleResize);
}

const breakpointContainers = Array.from(
  document.querySelectorAll('.breakpoint-container'),
) as HTMLElement[];
breakpointContainers.forEach(initBreakpointContainer);
